import BYMixins from "@/mixins/index/index.js";
import {
  Progress,
  Tooltip,
  message,
  badge,
  pagination,
  select,
} from "ant-design-vue";
import "ant-design-vue/lib/tooltip/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import "ant-design-vue/lib/progress/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import baseApi from "@/request/api/base.js";
import loginApi from "@/request/api/user.js";
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import titleCon from "@/components/title-con/index";
// import { heatmapData } from "./heatdata";
export default {
  name: `Overlay`,
  data() {
    return {
      dateList: [
        { name: "年", id: 0, check: false },
        { name: "月", id: 1, check: true },
      ],
      yearList: [
        {
          id: 1,
          name: "2022",
        },
        {
          id: 2,
          name: "2023",
        },
        {
          id: 3,
          name: "2024",
        },
        {
          id: 4,
          name: "2025",
        },
        {
          id: 5,
          name: "2026",
        },
        {
          id: 6,
          name: "2027",
        },
        {
          id: 7,
          name: "2028",
        },
        {
          id: 8,
          name: "2029",
        },
        {
          id: 9,
          name: "2030",
        },
      ],
      moonList: [
        {
          id: 1,
          name: "1月",
        },
        {
          id: 2,
          name: "2月",
        },
        {
          id: 3,
          name: "3月",
        },
        {
          id: 4,
          name: "4月",
        },
        {
          id: 5,
          name: "5月",
        },
        {
          id: 6,
          name: "6月",
        },
        {
          id: 7,
          name: "7月",
        },
        {
          id: 8,
          name: "8月",
        },
        {
          id: 9,
          name: "9月",
        },
        {
          id: 10,
          name: "10月",
        },
        {
          id: 11,
          name: "11月",
        },
        {
          id: 12,
          name: "12月",
        },
      ],
      type: 1,
      size: "default",
      format: "a",
      month: moment().format("M"), //时间,
      year: moment().format("YYYY"),
      beganTime: "",
      endTime: "",
      list: [],
      totalRecords: 0,
      loading: false,
      pageSize: 10,
      currentPage: 1,
      patrolResult: [], //区域
      patrolQuarter: [], //季度
      titleName: "按区域统计",
      turning: 0,
      lastyearList: [],
      thisyearList: [],
      yaerend: 0,
      yaerstart: 0,
    };
  },

  components: {
    "a-tooltip": Tooltip,
    "a-progress": Progress,
    "a-badge": badge,
    "a-pagination": pagination,
    "a-select": select,
    titleCon,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mixins: [],
  mounted() {
    this.$eventBus.$emit("changeNav", { index: 3, name: "base" });
    //默认获取当前年月份时分秒（月份）
    this.beganTime = moment().startOf("month").format("YYYY-MM-DD");
    this.endTime = moment().endOf("month").format("YYYY-MM-DD");
    this.getList();
    this.yaerend = moment().format("YYYY");
    this.yaerstart = moment().add(-1, "y").format("YYYY");
  },
  methods: {
    //列表数据
    async getList(cpage = 1) {
      this.currentPage = cpage;
      let item = this.userInfo;
      let params = {
        beganTime: `${this.beganTime} 00:00:00`,
        endTime: `${this.endTime} 23:59:59`,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      if (item.cityCode) {
        params.cityCode = item.cityCode;
      }
      if (item.districtCode) {
        params.districtCode = item.districtCode;
      }
      if (item.townCode) {
        params.townCode = item.townCode;
      }
      if (item.streetCode) {
        params.streetCode = item.streetCode;
      }
      try {
        let res = await baseApi.getList(params);
        let list = res.data.list;
        for (let index = 0; index < list.length; index++) {
          let element = list[index];
          element.createTime = element.createTime.substring(0, 10);
          if (element.image) {
            element.image = element.image.split(",");
          }
        }
        this.list = list;
        this.totalRecords = res.data.totalRecords;
      } catch (error) {
        console.log(error);
      }
    },
    //统计区域数据
    async getstatistics() {
      let item = this.userInfo;
      let params = {
        region: item.region,
        regionLevel: item.regionLevel,
        beganTime: `${this.beganTime} 00:00:00`,
        endTime: `${this.endTime} 23:59:59`,
      };
      //区域
      try {
        let res = await baseApi.patrolResult(params);
        res.data.forEach((element) => {
          if (element.regionName.length > 4) {
            element.regionName = element.regionName.substring(0, 2);
          }
        });
        this.patrolResult = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    // 季度统计
    async getjiduData() {
      let item = this.userInfo;
      let params = {
        region: item.region,
        regionLevel: item.regionLevel,
        beganTime: `${this.beganTime} 00:00:00`,
        endTime: `${this.endTime} 23:59:59`,
      };
      //季度
      try {
        let res = await baseApi.patrolQuarter(params);
        res.data.forEach((element) => {
          if (element.regionName.length > 4) {
            element.regionName = element.regionName.substring(0, 2);
          }
        });
        this.patrolQuarter = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    //获取年度 去年
    async getlastyearData() {
      let item = this.userInfo;
      let params = {
        region: item.region,
        regionLevel: item.regionLevel,
        beganTime: `${this.yaerstart}-01-31 00:00:00`,
        endTime: `${this.yaerstart}-12-31 23:59:59`,
      };
      //区域
      try {
        let res = await baseApi.patrolResult(params);
        res.data.forEach((element) => {
          if (element.regionName.length > 4) {
            element.regionName = element.regionName.substring(0, 2);
          }
        });
        this.lastyearList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    //获取当前年
    async getthisyearData() {
      let item = this.userInfo;
      let params = {
        region: item.region,
        regionLevel: item.regionLevel,
        beganTime: `${this.yaerend}-01-31 00:00:00`,
        endTime: `${this.yaerend}-12-31 23:59:59`,
      };
      //区域
      try {
        let res = await baseApi.patrolResult(params);
        res.data.forEach((element) => {
          if (element.regionName.length > 4) {
            element.regionName = element.regionName.substring(0, 2);
          }
        });
        this.thisyearList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    timeHand(item) {
      this.dateList.forEach((element) => {
        if (element.check) {
          element.check = false;
        }
      });
      item.check = true;
      this.type = item.id;
      this.month = "";
      if (item.id == 1) {
        //月
        this.month = moment().format("M"); //时间,
        this.beganTime = moment().startOf("month").format("YYYY-MM-DD");
        this.endTime = moment().endOf("month").format("YYYY-MM-DD");
        this.getparams();
      } else {
        this.year = moment().format("YYYY"); //时间,
        this.beganTime = moment().startOf("year").format("YYYY-MM-DD"); // 所在年开始时间
        this.endTime = moment().endOf("year").format("YYYY-MM-DD");
        this.getparams();
      }
    },
    onTimeChange(date, dateString) {
      console.log(dateString);
      if (this.type == 0) {
        //年份
        this.beganTime = `${dateString}-01-01`;
        this.endTime = `${dateString}-12-31`;
        this.getparams();
      } else {
        let start = moment().startOf("year").format("YYYY");
        this.beganTime = `${start}-${dateString}-01`;
        this.endTime = moment(this.beganTime)
          .endOf("month")
          .format("YYYY-MM-DD");
        //月份
        this.getparams();
      }
    },
    handlemoonClick(value) {
      let dateString = "";
      this.moonList.forEach((element) => {
        if (element.id == value) {
          dateString = element.name.slice(0, -1);
        }
      });
      this.month = dateString;
      let start = moment().startOf("year").format("YYYY");
      if (dateString.length < 2) {
        this.beganTime = `${start}-0${dateString}-01`;
      } else {
        this.beganTime = `${start}-${dateString}-01`;
      }
      this.endTime = moment(this.beganTime).endOf("month").format("YYYY-MM-DD");
      //月份
      this.getparams();
    },
    handleyearClick(value) {
      let dateString = "";
      this.yearList.forEach((element) => {
        if (element.id == value) {
          dateString = element.name;
        }
      });
      this.beganTime = `${dateString}-01-01`;
      this.endTime = `${dateString}-12-31`;
      this.getparams();
    },
    handleMenuClick(e) {
      console.log(e);
    },
    radioChange(e) {
      this.turning = 0;
      this.format = e.target.value;
      this.getparams();
    },
    handleTableChange(val) {
      this.currentPage = val;
      this.getList(val);
    },
    getparams() {
      if (this.format == "a") {
        this.getList(); //列表
      } else {
        // 统计
        this.getstatistics();
      }
    },
    regionChange(item) {},
    ychanage() {
      this.turning = this.turning + 1;
      if (this.turning == 1) {
        //点击了右边按钮获取季度
        this.titleName = "按季度统计";
        this.getjiduData();
      } else if (this.turning == 2) {
        //点击了右边按钮获取年度
        this.titleName = "按年度汇总";
        this.getlastyearData();
        this.getthisyearData();
      }
    },
    zChange() {
      this.turning = this.turning - 1;
      if (this.turning == 0) {
        //点击了左边按钮获取季度
        this.titleName = "按区域统计";
        this.getstatistics();
      } else if (this.turning == 1) {
        //点击了右边按钮获取季度
        this.titleName = "按季度统计";
        this.getjiduData();
      }
    },
  },
};
