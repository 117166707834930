import http from "../http.js";

const api = {
  getList: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/rjtPatrolRecord/queryByPage`,
      data: params,
    });
  },
  //统计 区域
  patrolResult: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/patrolResultStats`,
      data: params,
    });
  },
  //季度
  patrolQuarter: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/patrolQuarterStats`,
      data: params,
    });
  },
};

export default api;
